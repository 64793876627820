import { Survey, SurveyWithCode } from '../../models/count';
import _, { rest } from 'lodash';
import { ConclusionService, replaceQuestionsInPlace } from './conclusion';
import Store from '../../store';
import { Household, SubmissionWrapper, HeadOfHousehold, VolunteerRegistration } from '../../models/survey';
import uuid from 'uuid/v4';
import { FormType } from '../../models/formstructure';
import { AnswerDistribution } from '../../models/all';
import { Both } from "../../Both";

export const DataService = {
  mapVolunteersForExport(registrations: VolunteerRegistration[], id: string): any[] {
    const survey: SurveyWithCode = Store.getters.Survey(id);
    const dict = Store.getters.getPrettyNameDict(survey.volunteerSignupSurvey);

    const orderedByDate = _.orderBy(registrations, ['date'], ['asc']);
    const forDownload = orderedByDate.map(r => {
      const {FirstName, LastName, Email, ...rest} = r.answers;
      replaceQuestionsInPlace(rest as any, dict);
      return {
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Phone: r.phone,
        Team: r.team,
        SignedUp: new Date(r.date).toLocaleString(),
        ...rest,
      };
    });
    return forDownload;
  },
  mapForExport(surveys: Both[], survey: Survey,  useUUIDs: boolean): Record<string,string>[] {
    const forExport = [] as any[];
    const orderedByDate = _.orderBy(surveys, ['date'], ['asc']);
    orderedByDate.forEach((b: Both, i) => {
      const household = b.household;
      const whereHeadSlept = household.HouseholdMembers?.[0]?.['Where They Stayed Last Night'];
      household.HouseholdMembers.forEach((hm, j) => {
        const {
          ['Personal ID']: personalID,
          ...rest
        } = hm;

        const wherePersonSlept = hm['Where They Stayed Last Night'] ?? whereHeadSlept;
        if (wherePersonSlept) {
          rest['Where They Stayed Last Night'] = wherePersonSlept;
        }
        const toExport = {
          'Household ID': useUUIDs ? household.householdID : household.index,
          'Personal ID': useUUIDs ? hm['Personal ID'] : `${household.index}-${j+1}`,
          'Survey Type':
            household.type === FormType.PITCountObservation
              ? 'Observation'
              : 'Interview',
          ...rest,
          
          Volunteer: `${household.volunteerName}`,
          Captured: new Date(household.date).toLocaleString(),
          County: `${household.county ?? 'Unknown'}`,
          City: `${household.city ?? 'Unknown'}`,
          'Zip code': `${household.zipcode ?? 'Unknown'}`,
          'Best Guess Address': `${household.address ?? 'Unknown'}`,
          Lat: Array.isArray(household.latlng) ? household.latlng[1].toPrecision(7) : 'Unknown',
          Lng: Array.isArray(household.latlng) ? household.latlng[0].toPrecision(7) : 'Unknown',
          'Household ID Reference': household.householdID,
          'Personal ID Reference': personalID,
          'Survey status': b.conclusion.surveyStatus,
          'Housing status': b.conclusion.housingStatus,
          'Shelter status': b.conclusion.shelterStatus,
          'Needs attention': ConclusionService.HasIssue(b.conclusion) ? 'Yes' : 'No'
        } as any;
        if (household.customLocation) {
          toExport['Custom Location'] = household.customLocation;
        }
        if (household.teamName) {
          toExport['Team'] = household.teamName;
        }
        if (!!household.shelter) {
          console.log(survey.shelters)
          const foundShelterName = survey.shelters?.shelters.find(s => s.id === household.shelter)?.name;
          if (!!foundShelterName) toExport['Shelter'] = foundShelterName;
        }
        forExport.push(toExport);
      });
    });
    return forExport;
  },
  formatLocation(location: string | number[]) {
    if (Array.isArray(location)) {
      // Data is stored as long, lat in OpenLayers but most people expect lat, long.
      return `${location[1].toPrecision(7)}, ${location[0].toPrecision(7)}`;
    }
    return location;
  },
  formatPhone(phone: string): string | null {
    if (!phone) return phone;
    //normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, '');

    //check if number length equals to 10
    if (phone.length == 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }

    if (phone.length == 11) {
      //reformat and return phone number
      return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2) $3-$4');
    }

    return phone;
  },
  formatTime(date: number): string {
    return new Date(date).toLocaleString();
  },
  combineIfList(value: any): any {
    if (Array.isArray(value)) return value.join(', ');
    return value;
  },
  getQuestions(households: Household[]) {
    const questions: string[] = households
      .map((household: Household) => {
        const memberQuestions = household.HouseholdMembers.map(hm =>
          Object.keys(hm),
        )
          .flat(2)
          .filter(q => q !== 'HouseholdMembers');
        const nicerQuestions = memberQuestions.map(mq => this.nicer(mq));
        return nicerQuestions;
      })
      .flat(2);
    const uniqueQuestions = _.uniq(questions);
    return uniqueQuestions;
  },
  getHeadOfHouseholdQuestions(households: Household[]) {
    const newArray = [...households];
    const members: HeadOfHousehold[] = newArray
      .filter(h => h.HouseholdMembers && h.HouseholdMembers.length > 0)
      .map(h => h.HouseholdMembers[0]);

    return getFieldsFromMembers(members);
  },
  getHouseholdMemberQuestions(households: Household[]) {
    const newArray = [...households];
    const members: HeadOfHousehold[] = newArray
      .filter(h => h.HouseholdMembers.length > 1)
      .flatMap(h => h.HouseholdMembers?.slice(1) ?? [])

    return getFieldsFromMembers(members);
  },
  allNumbers(answers: string[]): boolean {
    for (const answer of answers) {
      const asNumber = parseInt(answer, 10);
      if (Number.isNaN(asNumber) && answer != '') {
        return false;
      }
    }
    return true;
  },
  getAnswerDict(both: Both[], question: string): AnswerDistribution {
    const answers: AnswerDistribution = new AnswerDistribution();
    for (const b of both) {
      const type = b.conclusion.shelterStatus;
      const household = b.household;
      for (const key of Object.keys(household)) {
        if (key === 'HouseholdMembers') {
          for (const member of household.HouseholdMembers) {
            for (const key of Object.keys(member)) {
              const niceKey = this.nicer(key);
              if (niceKey === question) {
                const answer = this.nicer((member as any)[key]);
                answers.addAnswer(type, answer);
              }
            }
          }
          continue;
        }
        const niceKey = this.nicer(key);
        if (niceKey === question) {
          const answer = this.nicer((household as any)[key]);
          answers.addAnswer(type, answer);
        }
      }
    }
    return answers;
  },
  getAnswers(households: Household[], question: string): string[] {
    const answers = [] as string[];
    for (const household of households) {
      for (const key of Object.keys(household)) {
        if (key === 'HouseholdMembers') {
          for (const member of household.HouseholdMembers) {
            for (const key of Object.keys(member)) {
              const niceKey = this.nicer(key);
              if (niceKey === question) {
                const answer = this.nicer((member as any)[key]);
                answers.push(answer);
              }
            }
          }
          continue;
        }
        const niceKey = this.nicer(key);
        if (niceKey === question) {
          const answer = this.nicer((household as any)[key]);
          answers.push(answer);
        }
      }
    }
    const unique = _.uniq(answers);
    if (!this.allNumbers(unique)) {
      unique.sort();
    } else {
      unique.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    }
    return unique;
  },
  nicer(ugly: any): string {
    if (ugly === null) return '';
    return this.combineIfList(ugly);
  },
  generateRandomId(): string {
    return uuid();
  },
  getCoCList() {
    return [
'AK-500',
'AK-501',
'AL-500',
'AL-501',
'AL-502',
'AL-503',
'AL-504',
'AL-506',
'AL-507',
'AR-500',
'AR-501',
'AR-503',
'AR-504',
'AR-505',
'AS-500',
'AZ-500',
'AZ-501',
'AZ-502',
'CA-500',
'CA-501',
'CA-502',
'CA-503',
'CA-504',
'CA-505',
'CA-506',
'CA-507',
'CA-508',
'CA-509',
'CA-510',
'CA-511',
'CA-512',
'CA-513',
'CA-514',
'CA-515',
'CA-516',
'CA-517',
'CA-518',
'CA-519',
'CA-520',
'CA-521',
'CA-522',
'CA-523',
'CA-524',
'CA-525',
'CA-526',
'CA-527',
'CA-529',
'CA-530',
'CA-531',
'CA-600',
'CA-601',
'CA-602',
'CA-603',
'CA-604',
'CA-606',
'CA-607',
'CA-608',
'CA-609',
'CA-611',
'CA-612',
'CA-613',
'CA-614',
'CO-500',
'CO-503',
'CO-504',
'CT-503',
'CT-505',
'DC-500',
'DE-500',
'FL-500',
'FL-501',
'FL-502',
'FL-503',
'FL-504',
'FL-505',
'FL-506',
'FL-507',
'FL-508',
'FL-509',
'FL-510',
'FL-511',
'FL-512',
'FL-513',
'FL-514',
'FL-515',
'FL-517',
'FL-518',
'FL-519',
'FL-520',
'FL-600',
'FL-601',
'FL-602',
'FL-603',
'FL-604',
'FL-605',
'FL-606',
'GA-500',
'GA-501',
'GA-502',
'GA-503',
'GA-504',
'GA-505',
'GA-506',
'GA-507',
'GA-508',
'GU-500',
'HI-500',
'HI-501',
'IA-500',
'IA-501',
'IA-502',
'ID-500',
'ID-501',
'IL-500',
'IL-501',
'IL-502',
'IL-503',
'IL-504',
'IL-506',
'IL-507',
'IL-508',
'IL-510',
'IL-511',
'IL-512',
'IL-513',
'IL-514',
'IL-515',
'IL-516',
'IL-517',
'IL-518',
'IL-519',
'IL-520',
'IN-502',
'IN-503',
'KS-502',
'KS-503',
'KS-505',
'KS-507',
'KY-500',
'KY-501',
'KY-502',
'LA-500',
'LA-502',
'LA-503',
'LA-505',
'LA-506',
'LA-507',
'LA-509',
'MA-500',
'MA-502',
'MA-503',
'MA-504',
'MA-505',
'MA-506',
'MA-507',
'MA-508',
'MA-509',
'MA-510',
'MA-511',
'MA-515',
'MA-516',
'MA-517',
'MA-519',
'MD-500',
'MD-501',
'MD-502',
'MD-503',
'MD-504',
'MD-505',
'MD-506',
'MD-507',
'MD-508',
'MD-509',
'MD-510',
'MD-511',
'MD-512',
'MD-513',
'MD-600',
'MD-601',
'ME-500',
'MI-500',
'MI-501',
'MI-502',
'MI-503',
'MI-504',
'MI-505',
'MI-506',
'MI-507',
'MI-508',
'MI-509',
'MI-510',
'MI-511',
'MI-512',
'MI-513',
'MI-514',
'MI-515',
'MI-516',
'MI-517',
'MI-518',
'MI-519',
'MI-523',
'MN-500',
'MN-501',
'MN-502',
'MN-503',
'MN-504',
'MN-505',
'MN-506',
'MN-508',
'MN-509',
'MN-511',
'MO-500',
'MO-501',
'MO-503',
'MO-600',
'MO-602',
'MO-603',
'MO-604',
'MO-606',
'MP-500',
'MS-500',
'MS-501',
'MS-503',
'MT-500',
'NC-500',
'NC-501',
'NC-502',
'NC-503',
'NC-504',
'NC-505',
'NC-506',
'NC-507',
'NC-509',
'NC-511',
'NC-513',
'NC-516',
'ND-500',
'NE-500',
'NE-501',
'NE-502',
'NH-500',
'NH-501',
'NH-502',
'NJ-500',
'NJ-501',
'NJ-502',
'NJ-503',
'NJ-504',
'NJ-506',
'NJ-507',
'NJ-508',
'NJ-509',
'NJ-510',
'NJ-511',
'NJ-512',
'NJ-513',
'NJ-514',
'NJ-515',
'NJ-516',
'NM-500',
'NM-501',
'NV-500',
'NV-501',
'NV-502',
'NY-500',
'NY-501',
'NY-503',
'NY-504',
'NY-505',
'NY-507',
'NY-508',
'NY-510',
'NY-511',
'NY-512',
'NY-513',
'NY-514',
'NY-516',
'NY-518',
'NY-519',
'NY-520',
'NY-522',
'NY-523',
'NY-525',
'NY-600',
'NY-601',
'NY-602',
'NY-603',
'NY-604',
'NY-606',
'NY-607',
'NY-608',
'OH-500',
'OH-501',
'OH-502',
'OH-503',
'OH-504',
'OH-505',
'OH-506',
'OH-507',
'OH-508',
'OK-500',
'OK-501',
'OK-502',
'OK-503',
'OK-504',
'OK-505',
'OK-506',
'OK-507',
'OR-500',
'OR-501',
'OR-502',
'OR-503',
'OR-505',
'OR-506',
'OR-507',
'PA-500',
'PA-501',
'PA-502',
'PA-503',
'PA-504',
'PA-505',
'PA-506',
'PA-508',
'PA-509',
'PA-510',
'PA-511',
'PA-512',
'PA-600',
'PA-601',
'PA-603',
'PA-605',
'PR-502',
'PR-503',
'RI-500',
'SC-500',
'SC-501',
'SC-502',
'SC-503',
'SD-500',
'TN-500',
'TN-501',
'TN-502',
'TN-503',
'TN-504',
'TN-506',
'TN-507',
'TN-509',
'TN-510',
'TN-512',
'TX-500',
'TX-503',
'TX-600',
'TX-601',
'TX-603',
'TX-604',
'TX-607',
'TX-611',
'TX-624',
'TX-700',
'TX-701',
'UT-500',
'UT-503',
'UT-504',
'VA-500',
'VA-501',
'VA-502',
'VA-503',
'VA-504',
'VA-505',
'VA-507',
'VA-508',
'VA-513',
'VA-514',
'VA-521',
'VA-600',
'VA-601',
'VA-602',
'VA-603',
'VA-604',
'VI-500',
'VT-500',
'VT-501',
'WA-500',
'WA-501',
'WA-502',
'WA-503',
'WA-504',
'WA-508',
'WI-500',
'WI-501',
'WI-502',
'WI-503',
'WV-500',
'WV-501',
'WV-503',
'WV-508',
'WY-500',
    ]
  },
};

function getFieldsFromMembers(member: HeadOfHousehold[]): string[] {
  const keys = member.flatMap(m => Object.keys(m))
    .flat(1)
    .filter(q => !Array.isArray(q) && q !== 'HouseholdMembers');
  return _.uniq(keys);
}
