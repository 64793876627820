
import Vue from 'vue';
import { DataService } from '@/services/pure/data';
import { DownloadService } from '@/services/misc/download';
import { Household } from '@/models/survey';
import { HMISFinalExportService } from '@/services/pure/hmis/hmis-finalExport';
import { CountService } from '@/services/count';
import { SurveyWithCode } from '@/models/count';
import { EntryService } from '@/services/entry';
import { SubmissionsToKML } from '@/services/pure/kmz-creation';
import { Both } from '../../Both';
interface DownloadOption {
  text: string;
  value: string;
  description: string;
}
export default Vue.extend({
  props: ['all', 'filtered', 'selected', 'survey'],
  data() {
    return {
      displayDownloadTypeDialog: false,
      selectedHouseholds: [] as Both[],
      selectedDownloadOption: {} as DownloadOption,
      includeObservational: false,
      downloadOptions: [
        {
          text: 'Raw CSV',
          value: 'raw',
          description:
            'Human-readable and includes all questions answered and additional metadata (e.g. time, volunteer, location).',
        },
        {
          text: 'HMIS CSV',
          value: 'hud',
          description:
            'Can be imported into some HMIS systems (like ServicePoint). All required PIT data is included automatically, but you can configure additional fields for export (like SSN and Date of Birth).',
        },
        {
          text: 'As hotspots',
          value: 'hotspots',
          description:
            'Survey locations can be imported into a future PIT count or survey as hotspots.',
        },
      ] as DownloadOption[],
    };
  },
  computed: {
    isPITCount(): boolean {
      return this.survey.type === 'PIT Count';
    },
    live(): Both[] {
      return this.all.filter((a: Both) => a.conclusion.surveyStatus === 'Live')
    },
  },
  mounted() {
    this.selectedDownloadOption = this.downloadOptions[0];
    this.includeObservational = this.survey.includeObservationalInExport;
  },
  methods: {
    async downloadRaw() {
      const surveysToExport = DataService.mapForExport(this.selectedHouseholds, this.survey, this.$store.getters.preferences?.useUUIDs ?? false);
      await DownloadService.downloadCSV(surveysToExport, 'survey_data.csv');
    },
    async downloadHMIS() {
      this.saveIncludeObservationalToggle();
      let surveys = [...this.selectedHouseholds].map(b => b.household);
      if (!this.includeObservational) {
        const observationCount = surveys.filter(s => s.IsObservationSurvey)
          .length;
        surveys = surveys.filter(s => !s.IsObservationSurvey);
      }
      const survey = this.$store.getters.Survey(this.survey.code);
      const mappings = survey.hmisMappings;
      const surveysToExport = HMISFinalExportService.getIndividualLevelData(
        surveys,
        mappings,
      );
      await DownloadService.downloadCSV(surveysToExport.Clients, 'Client.csv');
      await DownloadService.downloadCSV(
        surveysToExport.Enrollments,
        'Enrollment.csv',
      );
      await DownloadService.downloadCSV(
        surveysToExport.Disabilities,
        'Disabilities.csv',
      );
      await DownloadService.downloadCSV(
        surveysToExport.HealthAndDV,
        'HealthAndDV.csv',
      );
    },
    async downloadHotspots() {
      // Construct kmz
      const result = SubmissionsToKML(this.survey, this.selectedHouseholds.map(b => b.household));
      // Looks like I can use JSZip to create everything in memory and end up with the zip: https://stuk.github.io/jszip/
      // zip compressed folder with 1. images folder and 2. kml (xml).

      // Download kmz
      DownloadService.downloadKmlStringAsKmz(result, this.survey.name);
    },
    async download() {
      switch (this.selectedDownloadOption.value) {
        case 'raw':
          await this.downloadRaw();
          break;
        case 'hud':
          await this.downloadHMIS();
          break;
        case 'hotspots':
          await this.downloadHotspots();
          break;
      }
      this.displayDownloadTypeDialog = false;
    },
    initiateDownload(boths: Both[]) {
      this.selectedHouseholds = boths;
      if (this.isPITCount) this.displayDownloadTypeDialog = true;
      else {
        this.downloadRaw();
      }
    },
    saveIncludeObservationalToggle() {
      const survey: SurveyWithCode = { ...this.survey };
      survey.includeObservationalInExport = this.includeObservational;
      CountService.update(survey);
    },
  },
});
