
import { Survey, TeamAssignment } from '@/models/count';
import { VolunteerRegistration } from '@/models/survey';
import { DataService } from '@/services/pure/data';
import * as _ from 'lodash';
import Vue, { PropType } from 'vue';
import { RegionMap } from '../../../models/Organization';
import { FirebaseRepository } from '../../../repositories/firebase';
import { DownloadService } from '../../../services/misc/download';
import { Notification } from '../../../services/misc/notification';
type Display = {
  Name: string;
  Phone: string | null;
  Email: string;
  Team?: string;
  ID: string;
};
export default Vue.extend({
  props: {
    id: {
      type: String,
    },
    registrations: {
      type: Array as PropType<VolunteerRegistration[]>,
    },
  },
  data() {
    return {
      search: '',
      selected: [],
    };
  },
  computed: {
    change(): boolean {
      // const fresh = this.registrationsToDisplay(this.registrations);
    //  return !_.isEqual(this.data, fresh);
    // TODO - for some reason saves are being blocked even with valid changes.
    return true;
    },
    headers(): { text: string; value: string; sortable?: boolean }[] {
      return [
        { text: 'Name', value: 'Name' },
        { text: 'Phone', value: 'Phone' },
        { text: 'Email', value: 'Email' },
        ...(this.hasTeams ? [{ text: 'Team', value: 'Team' }] : []),
      ];
    },
    hasTeams(): boolean {
      const survey: Survey = this.$store.get('survey/survey');
      return !!survey.regionMap;
    },
    teams() {
      const survey: Survey = this.$store.get('survey/survey');
      const regionMap: RegionMap = JSON.parse(survey.regionMap as string);
      return regionMap.regions.features.map(r => r.properties!.name);
    },
    data(): Display[] {
      return this.registrationsToDisplay(this.registrations);
    },
  },
  methods: {
    registrationsToDisplay(registrations: VolunteerRegistration[]): Display[] {
      if (!registrations || registrations.length == 0) return [];
      return registrations.map(r => {
        return {
          Name: `${r.answers.FirstName} ${r.answers.LastName}`,
          Phone: r.phone,
          Team: r.team,
          Email: r.answers.Email,
          ID: r.id,
        };
      });
    },
    async save() {
      const assignments: TeamAssignment[] = this.data.map(d => {
        return {
          Team: d.Team as string,
          ID: d.ID,
        }
      });
      const result = await FirebaseRepository.saveTeamAssignments(this.id, assignments);
      if (!result) {
        Notification.methods.send('error', 'Something went wrong. Please try again later.')
      } else {
        Notification.methods.send('success', 'Teams saved.')
      }
                                                                                                                                                                                      
    },
    async download() {
      // Include date of registration and order by it.
      // Map questions and answers to labels.
      const mapped = DataService.mapVolunteersForExport(
        this.registrations,
        this.id,
      );
      await DownloadService.downloadCSV(mapped, 'volunteers.csv');
    },
  },
});
