import { Condition } from './../../models/adminadjustment';
import { CountYear } from './../pure/breakdown';
import {
  HousingStatus,
  SurveyStatus,
  ApprovalStatus,
  ShelterStatus,
} from '@/models/adminadjustment';
import { ages, Field } from '@/models/all';
import {
  GenderAnalytics2021,
  GenderAnalytics2022,
} from '../../models/analytics';
import { Gender } from '../../models/household-enums';
import { FormType } from '@/models/formstructure';

export const GroundTruthService = {
  GetGendersWithTitlesConclusions(year: CountYear): { value: Gender; text: string }[] {
    if (year === '2021')
      return [
        { value: 'Female', text: 'Female' },
        { value: 'Male', text: 'Male' },
        { value: 'Transgender MTF', text: 'Transgender MTF' },
        { value: 'Transgender FTM', text: 'Transgender FTM' },
        { value: 'Gender Non-Conforming', text: 'Gender Non-Conforming' },
        { value: 'Unknown', text: 'Unknown' },
      ];
    if (year === '2022' || year === '2023') {
      return [
        { value: 'Female', text: 'Female' },
        { value: 'Male', text: 'Male' },
        { value: 'Transgender', text: 'Transgender' },
        { value: 'Questioning', text: 'Questioning' },
        {
          value: 'Gender Non-Conforming',
          text: "A gender that is not singularly 'Female' or 'Male'",
        },
        { value: 'Multiple', text: 'Multiple' },
        { value: 'Unknown', text: 'Unknown' },
      ];
    }
    return [
      { value: 'Female', text: 'Woman (Girl, if child)' },
      { value: 'Male', text: 'Man (Boy, if child)' },
      {
        value: 'Culturally Specific Identity',
        text: 'Culturally Specific Identity (e.g., TwoSpirit)',
      },
      { value: 'Transgender', text: 'Transgender' },
      { value: 'Questioning', text: 'Questioning' },
      {
        value: 'Gender Non-Conforming',
        text: 'Non-Binary',
      },
      {
        value: 'Other',
        text: 'Different Identity',
      },
      { value: 'Unknown', text: 'Unknown' },
    ];
  },
  GetGenders(year: CountYear): Gender[] {
    if (year === '2021')
      return [
        'Male',
        'Female',
        'Transgender MTF',
        'Transgender FTM',
        'Gender Non-Conforming',
      ];
    else
      return [
        'Female',
        'Male',
        'Transgender',
        'Gender Non-Conforming',
        'Questioning',
        'Multiple',
        'Unknown',
      ];
  },
  GetGendersWithTitles(year: CountYear): { value: Gender; text: string }[] {
    if (year === '2021')
      return [
        { value: 'Female', text: 'Female' },
        { value: 'Male', text: 'Male' },
        { value: 'Transgender MTF', text: 'Transgender MTF' },
        { value: 'Transgender FTM', text: 'Transgender FTM' },
        { value: 'Gender Non-Conforming', text: 'Gender Non-Conforming' },
        { value: 'Unknown', text: 'Unknown' },
      ];
    if (year === '2022' || year === '2023') {
      return [
        { value: 'Female', text: 'Female' },
        { value: 'Male', text: 'Male' },
        { value: 'Transgender', text: 'Transgender' },
        { value: 'Questioning', text: 'Questioning' },
        {
          value: 'Gender Non-Conforming',
          text: "A gender that is not singularly 'Female' or 'Male'",
        },
        { value: 'Multiple', text: 'Multiple' },
        { value: 'Unknown', text: 'Unknown' },
      ];
    }
    return [
      { value: 'Female', text: 'Woman (Girl, if child)' },
      { value: 'Male', text: 'Man (Boy, if child)' },
      {
        value: 'Culturally Specific Identity',
        text: 'Culturally Specific Identity (e.g., TwoSpirit)',
      },
      { value: 'Transgender', text: 'Transgender' },
      { value: 'Questioning', text: 'Questioning' },
      {
        value: 'Gender Non-Conforming',
        text: 'Non-Binary',
      },
      {
        value: 'Other',
        text: 'Different Identity',
      },
      {
        value: 'Multiple',
        text: 'Multiple',
      },
      { value: 'Unknown', text: 'Unknown' },
    ];
  },
  GetGenders(year: CountYear): Gender[] {
    if (year === '2021')
      return [
        'Male',
        'Female',
        'Transgender MTF',
        'Transgender FTM',
        'Gender Non-Conforming',
      ];
    else
      return [
        'Female',
        'Male',
        'Transgender',
        'Gender Non-Conforming',
        'Questioning',
        'Multiple',
        'Unknown',
      ];
  },
  GetRacesWithTitlesConclusions(year: CountYear) {
    if (['2021', '2022', '2023'].includes(year))
      return [
        {
          value: 'American Indian/Alaskan Native',
          text: 'American Indian, Alaska Native, or Indigenous',
        },
        { value: 'Asian', text: 'Asian or Asian American' },
        {
          value: 'Black/African American',
          text: 'Black, African American, or African',
        },
        {
          value: 'Native Hawaiian/Other Pacific Islander',
          text: 'Native Hawaiian or Pacific Islander',
        },
        {
          value: 'White',
          text: 'White',
        },
        { value: 'Unknown', text: 'Unknown' },
      ];

    return [
      {
        value: 'American Indian/Alaskan Native',
        text: 'American Indian, Alaska Native, or Indigenous',
      },
      {
        value: 'Asian',
        text: 'Asian or Asian American',
      },
      {
        value: 'Black/African American',
        text: 'Black, African American, or African',
      },
      {
        value: 'Native Hawaiian/Other Pacific Islander',
        text: 'Native Hawaiian or Pacific Islander',
      },
      {
        value: 'White',
        text: 'White',
      },
      {
        value: 'White & Hispanic/Latina/e/o',
        text: 'White & Hispanic/Latina/e/o',
      },
      {
        value: 'Middle Eastern or North African',
        text: 'Middle Eastern or North African',
      },
      {
        value: 'Hispanic/Latina/e/o',
        text: 'Hispanic/Latina/e/o',
      },
      { value: 'Unknown', text: 'Unknown' },
    ];
  },
  GetRacesWithTitles(year: CountYear) {
    if (['2021', '2022', '2023'].includes(year))
      return [
        {
          value: 'American Indian/Alaskan Native',
          text: 'American Indian, Alaska Native, or Indigenous',
        },
        { value: 'Asian', text: 'Asian or Asian American' },
        {
          value: 'Black/African American',
          text: 'Black, African American, or African',
        },
        {
          value: 'Native Hawaiian/Other Pacific Islander',
          text: 'Native Hawaiian or Pacific Islander',
        },
        {
          value: 'White',
          text: 'White',
        },
        { value: 'Unknown', text: 'Unknown' },
      ];

    return [
      {
        value: 'American Indian/Alaskan Native',
        text: 'American Indian, Alaska Native, or Indigenous',
      },
      {
        value: 'American Indian/Alaskan Native & Hispanic/Latina/e/o',
        text: 'American Indian, Alaska Native, or Indigenous & Hispanic/Latina/e/o',
      },
      {
        value: 'Asian',
        text: 'Asian or Asian American',
      },
      {
        value: 'Asian & Hispanic/Latina/e/o',
        text: 'Asian or Asian American & Hispanic/Latina/e/o',
      },
      {
        value: 'Black/African American',
        text: 'Black, African American, or African',
      },
      {
        value: 'Black/African American & Hispanic/Latina/e/o',
        text: 'Black, African American, or African & Hispanic/Latina/e/o',
      },
      {
        value: 'Native Hawaiian/Other Pacific Islander',
        text: 'Native Hawaiian or Pacific Islander',
      },
      {
        value: 'Native Hawaiian/Other Pacific Islander & Hispanic/Latina/e/o',
        text: 'Native Hawaiian or Pacific Islander & Hispanic/Latina/e/o',
      },
      {
        value: 'White',
        text: 'White',
      },
      {
        value: 'White & Hispanic/Latina/e/o',
        text: 'White & Hispanic/Latina/e/o',
      },
      {
        value: 'Middle Eastern or North African',
        text: 'Middle Eastern or North African',
      },
      {
        value: 'Middle Eastern or North African & Hispanic/Latina/e/o',
        text: 'Middle Eastern or North African & Hispanic/Latina/e/o',
      },
      {
        value: 'Hispanic/Latina/e/o',
        text: 'Hispanic/Latina/e/o',
      },
      {
        value: 'Multi-racial & Hispanic/Latina/e/o',
        text: 'Multi-racial & Hispanic/Latina/e/o',
      },
      {
        value: 'Multi-racial (not Hispanic/Latina/e/o)',
        text: 'Multi-racial (not Hispanic/Latina/e/o)',
      },
      { value: 'Unknown', text: 'Unknown' },
    ];
  },
  GetRaces(year: CountYear) {
    if (['2021', '2022', '2023'].includes(year))
      return [
        'American Indian/Alaskan Native',
        'Asian',
        'Black/African American',
        'Native Hawaiian/Other Pacific Islander',
        'White',
        'Multiple',
      ];

    return [
      'American Indian/Alaskan Native',
      'Asian',
      'Black/African American',
      'Native Hawaiian/Other Pacific Islander',
      'White',
      'Hispanic/Latina/e/o',
      'Middle Eastern or North African',
    ];
  },
  GetRaceReportingCategories() {
    return [
      'American Indian, Alaska Native, or Indigenous',
      'American Indian, Alaska Native, or Indigenous & Hispanic/Latina/e/o',
      'Asian or Asian American',
      'Asian or Asian American & Hispanic/Latina/e/o',
      'Black/African American',
      'Black, African American, or African & Hispanic/Latina/e/o',
      'Native Hawaiian/Other Pacific Islander',
      'Native Hawaiian or Pacific Islander & Hispanic/Latina/e/o',
      'White',
      'White & Hispanic/Latina/e/o',
      'Hispanic/Latina/e/o',
      'Middle Eastern or North African',
      'Middle Eastern or North African & Hispanic/Latina/e/o',
      'Multi-racial & Hispanic/Latina/e/o',
      'Multi-racial (not Hispanic/Latina/e/o)',
      'Unknown'
    ];
  },
  GetRelations() {
    return [
      'Head',
      'Child',
      'Spouse',
      'Non-Married Partner',
      'Other Family',
      'Other, Non-Family',
    ];
  },
  GetEthnicities() {
    return ['Hispanic', 'Non-Hispanic'];
  },
  GetHouseholdTypes() {
    return [
      'Adults & Children',
      'Adults Only',
      'Unaccompanied Youth',
      'Parenting Youth',
    ];
  },
  GetSurveyStatuses(): SurveyStatus[] {
    return ['Live', 'Training', 'Duplicate', 'Invalid'];
  },
  GetHousingStatuses(): HousingStatus[] {
    return ['Chronically Homeless', 'Homeless', 'Not Literally Homeless'];
  },
  GetShelterStatuses(): ShelterStatus[] {
    return [
      'Unsheltered',
      'Transitional Housing',
      'Emergency Shelter',
      'Safe Haven',
    ];
  },
  GetApprovalStatuses(): ApprovalStatus[] {
    return ['Confirmed', 'Unconfirmed', 'Need Attention'];
  },
  GetConditions(): Condition[] {
    return [
      'Physical Disability',
      'Serious Mental Illness',
      'Substance Abuse Disorder',
      'HIV/AIDS',
      'Developmental Disability',
      'None',
    ];
  },
  GetAnswers(field: Field, year: CountYear) {
    switch (field) {
      case 'Age':
        return ages(year);
      case 'Ethnicity':
        return this.GetEthnicities();
      case 'Gender':
        return this.GetGenders(year);
      case 'Race':
        return this.GetRaces(year);
      // e.g. Veteran
      default:
        return ['Yes', 'No'];
    }
  },
};
