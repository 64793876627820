
import Vue, { PropType } from 'vue';
import { SurveyService } from '../../services/pure/surveys';
import * as _ from 'lodash';
import { SurveyMapIcon, Household } from '@/models/survey';
import { DataService } from '@/services/pure/data';
import GeoJSON from 'ol/format/GeoJSON';
import DragBox from 'ol/interaction/DragBox';
import Feature from 'ol/Feature';
import { transformExtent } from 'ol/proj';
import { defaults as defaultControls, Control } from 'ol/control';
import { ConclusionService, isAdult } from '@/services/pure/conclusion';
import { FormType } from '@/models/formstructure';
import { EntryService } from '@/services/entry';
import firebase, { storage } from 'firebase';
import { FirebaseRepository } from '@/repositories/firebase';
import Boundaries from '@/components/Maps/Components/Boundaries.vue';
import RegionBoundaries from '@/components/Maps/Components/RegionBoundaries.vue';
import Hotspots from '@/components/Maps/Components/Hotspots.vue';
import { FeatureCollection } from '@turf/turf';
import GeographicService from '@/services/pure/geo';
import { Survey, SurveyWithCode } from '@/models/count';
import HouseholdMarkers from './Components/HouseholdMarkers.vue';
type Color = 'Green' | 'Blue' | 'Red' | 'Black';

export default Vue.extend({
  components: {Boundaries, RegionBoundaries, HouseholdMarkers, Hotspots},
    props: {
    submissions: {
      type: Array as PropType<Household[]>
    },
    initialZoom: {
      type: Number
    },
    survey: {
      type: Object as PropType<SurveyWithCode>
    }
  },
  data() {
    return {
      cocBoundaryUrl: '',
      hasHotspots: false,
      showHotspots: false,
      zoom: 4.5,
      location: [-97, 35],
      toggle: false,
      ready: false,
      rotation: 0,
      household: this.submissions[0] as Household,
      hotspot: null,
      filterBy: 'Household Type',
      overlay: {
        visible: true,
        location: [0, 0],
      },
      selectedFeatures: [],
      dragBoxEnabled: false,
      controls: defaultControls().extend([getSelectPinsControl(this)]),
      selectFeatureBoundingBox: [] as number[],
    };
  },
  mounted(): void {
    this.getLocation();
    this.getCoCBoundary();
    this.checkForHotspots();
  },
  computed: {
    filterOptions(): string[] {
      const baseFilters = [
        'Household Type',
        'Veteran',
        'Observation',
        'Chronically Homeless',
      ];
      if (!this.hasTeams)
        return baseFilters;
      return [...baseFilters, 'Team']
    },
    hasTeams(): boolean {
      const survey: Survey = this.$store.get('survey/survey');
      return !!survey.regionMap;
    },
    isPITCount(): boolean {
      return this.survey.type === 'PIT Count';
    },
    surveyID(): string {
      return this.survey.code;
    },
    fullscreen(): boolean {
      return this.$router.currentRoute.name !== 'survey';
    },
    showLegend(): boolean {
      return this.$router.currentRoute.name === 'survey';
    },
    showLinkToHousehold(): boolean {
      return this.$router.currentRoute.name === 'survey';
    },
    surveys(): Household[] {
      const surveys: Household[] = this.submissions;
     const surveysWithAutomatedLocation: Household[] = surveys.filter(
       (s: Household) => Array.isArray(s.latlng),
     );
     return surveysWithAutomatedLocation;    },
    features(): any {
      return this.surveys.map((s: Household, i: number) => {
        return {
          type: 'Feature',
          id: s.householdID,
          geometry: {
            type: 'Point',
            coordinates: s.latlng,
          },
        };
      });
    },
    icons(): SurveyMapIcon[] {
      if (this.filterBy === 'Team') return [];
      const iconsMap = {
        'Household Type': {
          Youth: 'Green',
          Adults: 'Blue',
          Both: 'Red',
        },
        Veteran: {
          Standard: 'Blue',
          Veteran: 'Red',
        },
        Observation: {
          Standard: 'Blue',
          Observation: 'Green',
        },
        'Chronically Homeless': {
          Standard: 'Blue',
          'Chronic': 'Black',
        },
      };
      const category = (iconsMap as any)[this.filterBy];
      const options = Object.entries(category);
      const fullPaths = SurveyService.getIconPaths();
      const mapped = options.map(o => {
        const color = o[1] as Color;
        return {
          Name: o[0],
          ImagePath: fullPaths[color],
        };
      });
      return mapped;
    },
    surveyCount(): number {
      return this.submissions.length;
    },
    householdMemberSummary(): string {
      const aa = EntryService.GetAdminAdjustment(
        this.household,
        this.surveyID,
      );
      const memberAges = aa.members.map(m => m.Age);
      const adultCount = memberAges.filter(a => isAdult(a)).length;
      const adults = adultCount + (adultCount === 1 ? ' adult' : ' adults');
      const youthCount = memberAges.filter(a => a === '18-24').length;
      const youths = youthCount + (youthCount === 1 ? ' youth' : ' youths');
      const childCount = memberAges.filter(a => a === 'Under 18').length;
      const children = childCount + (childCount === 1 ? ' child' : ' children');
      const unknownCount =
        memberAges.length - adultCount - youthCount - childCount;
      const unknown = unknownCount + ' unknown age';
      let summaryPieces = [adults, youths, children, unknown];
      summaryPieces = summaryPieces.filter(p => p[0] !== '0');
      return summaryPieces.join(', ');
    },
    volunteerName(): string {
      return this.household.volunteerName;
    },
    volunteerPhone(): string | null {
      return DataService.formatPhone(this.household.volunteerPhone);
    },
    formType(): string {
      // TODO - refactor this with the list in formstructure.ts.
      return this.household.type === FormType.PITCountObservation
        ? 'Observation'
        : 'Interview';
    },
  },
  methods: {
    async checkForHotspots() {
      const survey: SurveyWithCode = this.$store.get('survey/survey');
    const newLocationHotspots = this.$store.getters.hotspots(survey.code);
    this.hasHotspots = !!newLocationHotspots;
    },
    async getCoCBoundary() {
      const coc = this.$store.getters.CoC;
      if (!coc) return;
      const url = await FirebaseRepository.getCoCShapefileUrl(coc);
      if (!url) return;
      this.cocBoundaryUrl = url;
    },
    async getLocationFromCoC() {
           // If CoC boundaries, use that.
      const coc = this.$store.getters.CoC;
      const zoomingOnSingleSurvey = this.fullscreen;
      if (!coc || zoomingOnSingleSurvey) return false;
      const url = await FirebaseRepository.getCoCShapefileUrl(coc);
      if (!url) return false;
        const response = await fetch(url);
        const json: FeatureCollection = await response.json();
        const center = GeographicService.getCenter(json);
        this.location = center.geometry.coordinates;
        this.zoom = this.initialZoom;
        this.ready = true;
       return true;

    },
    async getLocationFromSurveys() {
      const locations = this.surveys.map(s => s.latlng).filter(s => Array.isArray(s));
      if (locations.length > 0) {
        this.zoom = this.initialZoom;
        const lat = _.mean(locations.map(l => l[0]));
        const long = _.mean(locations.map(l => l[1]));
        this.location = [lat, long];
        this.ready = true;
        return;
      }
      this.ready = true
      return [-97, 35];

    },
    async getLocation() {
      const done = await this.getLocationFromCoC();
      if (done) return;
      await this.getLocationFromSurveys();
    },
    openHousehold(): void {
      this.$router.push({
        path: `/survey/${this.surveyID}/household/${this.household.householdID}`,
      });
    },
    fromHousehold(household: Household): string {
      const icons = SurveyService.getIconPaths();
      const aa = EntryService.GetAdminAdjustment(household, this.surveyID);
      const childCount = aa.members
        .map(m => m.Age)
        .filter(a => a === 'Under 18' || a === '18-24').length;
      if (childCount === aa.members.length) return icons.Green;
      if (childCount > 0) return icons.Red;
      return icons.Blue;
    },
    clickHandler(payload: any) {
      this.toggle = !this.toggle;
      // Fix lint errors.
      const that: any = this;
      that.overlay.visible = false;
      that.$refs.map.forEachFeatureAtPixel(payload.pixel, (p: any) =>
        displayPopupForFeature(this, p),
      );
    },
    mapCreated(map: any) {
      const dragBox = this.getDragBox(map.$map);
      map.$map.addInteraction(dragBox);
    },
    getDragBox(map: any) {
      const that: any = this;
      const dragBox = new DragBox({
        condition: event => {
          return this.dragBoxEnabled;
        },
        onBoxEnd: () => {
          const geoJSON = new GeoJSON();
          const extent = dragBox.getGeometry().getExtent();
          const source = (this.$refs.vectorSource as any).$source;
          const selectInteraction = that.$refs.selectInteraction;

          source.forEachFeatureIntersectingExtent(
            extent,
            (feature: Feature) => {
              const geoFeature = geoJSON.writeFeatureObject(feature);
              that.selectedFeatures.push(geoFeature);
              selectInteraction.addFeature(feature);
            },
          );

          // Logic to show/hide the export selected button.
          const controls: any = map.getControls()?.getArray();
          const exportControl = controls.find((c: any) => c.element.id === 'exportSelectedPinsControl');


          this.selectFeatureBoundingBox = dragBox.getGeometry().getCoordinates();
          this.toggleMapDragBox();
          if (that.selectedFeatures.length > 0) {
            that.exportSelectedPins();
          }
        },
      });

      dragBox.on('boxstart', () => {
        that.selectedFeatures = [];
      });

      return dragBox;
    },
    toggleMapDragBox() {
      this.dragBoxEnabled = !this.dragBoxEnabled;
      const button = document.getElementById('selectPinsControlButton');
      // Styling of the control icon when selected/deselected.
      if (this.dragBoxEnabled) {
        button!.style.backgroundColor = '#003c88b3'; // Color for hover/focus.
      } else {
        button!.style.backgroundColor = '#003c8880'; // Standard color.
      }
    },
    exportSelectedPins(event: any) {
      const query = { ...this.$route.query };
      query['boundingBox'] = JSON.stringify(this.selectFeatureBoundingBox as any);
      this.$router.push({ path: this.$route.path, query });
      this.selectedFeatures = [];
    },
  },
});

async function displayHotspotInformation(that: any, feature: {values_: {description?: string, name?: string, geometry: { flatCoordinates: number[]}}}) {
  that.household = null;
  const name = feature.values_.name;
  const description = feature.values_.description;
  that.hotspot = {name, description};
  const [lat, long, _] = feature.values_.geometry.flatCoordinates;
      that.overlay = {
        location: [lat, long],
        visible: true,
      };
}

async function displayHousehold(that: any, household: Household) {
  that.hotspot = null;
  const featureBaseCoordinates = household.latlng;
  that.household = household;
  that.overlay = {
    location: featureBaseCoordinates,
    visible: true,
  };

}

async function displayPopupForFeature(that: any, feature: any) {
  const householdIndex = feature.id_;
  const household = that.surveys.find(
    (s: Household) => s.householdID === householdIndex,
  );

  // Technically they can click on the region boundary feature.
  if (household) {
    displayHousehold(that, household);
    return;
  } else {
    displayHotspotInformation(that, feature);
  }
}

function getSelectPinsControl(that: any): Control {
  const icon = document.createElement('i');
  icon.className = 'v-icon mdi mdi-selection';
  icon.style.fontSize = '20px';

  const button = document.createElement('button');
  button.appendChild(icon);
  button.setAttribute('id', 'selectPinsControlButton');
  button.addEventListener('click', that.toggleMapDragBox, false);

  const container = document.createElement('div');
  container.className = 'ol-control ol-unselectable';
  container.style.top = '4.2em';
  container.style.left = '0.5em';

  container.appendChild(button);

  return new Control({
    element: container,
  });
}
